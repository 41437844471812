import React from 'react';
import Slider from 'react-slick';

import img1 from './carousel/london.jpg';
import img2 from './carousel/family.jpg';
import img3 from './carousel/street2.jpg';
import img4 from './carousel/wings.jpg';
import img5 from './carousel/people.jpg';
import img6 from './carousel/Chrome.jpg';
import img7 from './carousel/hal1.jpg';
import img8 from './carousel/hal2.jpg';

// ... other imports

  

const ImageCarousel = () => {
    
    // Settings for the slider
    const settings = {
        dots: true,
        infinite: true,
        speed: 1500,
        slidesToShow: 1,
        slidesToScroll: 1,
        cssEase: 'linear',
        lazyLoad: 'ondemand',
        autoplay: true, // Enable auto-scroll
        autoplaySpeed: 5000, 
         
      customPaging: i => (
        <div className="text-dark">
          <button className="transition-all duration-300 ease-in-out focus:outline-none">
            <span className="text-dark">{i + 1}</span>
          </button>
        </div>
      ),
      // ... Rest of the settings
    };
  
    // Replace these with your actual images
    const images = [
      img1,
      img2,
      img3,
      img4,
      img5,
      img6,
      img7,
      img8,
      // ... more images
    ];

  return (
    <div className="mx-auto p-10 sm:p-10 md:p-20 lg:p-32 overflow-hidden">
      <Slider {...settings}>
        {images.map((img, idx) => (
          <div key={idx} className="outline-none ">
            <img src={img} alt={`Slide ${idx + 1}`} className="w-full h-auto mb-10" />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ImageCarousel;
