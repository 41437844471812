import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import AboutUs from "./pages/AboutUs";
import NavBar from "./pages/components/NavBar";
import CaseStudyDetail from "./pages/components/CaseStudyDetail";
import ScrollToTop from "./pages/components/ScrollToTop";
import FilteredCaseStudies from "./pages/components/FilteredCaseStudies";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <NavBar /> {/* This ensures NavBar is displayed on every page */}
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/case-study/:id" element={<CaseStudyDetail />} />
        <Route path="/case-studies/:category" element={<FilteredCaseStudies />} />
      </Routes>
    </Router>
  );
}

export default App;
