import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {

  const currentYear = new Date().getFullYear();
  return (
    <footer className="bg-dark text-white py-10">
      <div className=" lg:grid lg:grid-cols-5 md:grid md:grid-cols-6 mx-auto justify-between px-10 sm:px-20 lg:px-24 sm:pb-20 lg:pb-10 mt-1 md:mt-4 lg:mt-10">
        <div className="mb-6 lg:mb-0 lg:col-span-2 md:col-span-3 font-light font-inter text-base">
          <h2 className="lg:text-3xl md:text-1xl sm:text-xl text-xl font-bold mb-8  tracking-tighter leading-tight border-b-2 pb-5 border-cyan-500 inline-block ">Contact me</h2>
          <p className='mb-4'>Since I am open to work with companies           
            regardless <br/>  of time zone, email communication is the best way <br/> to get in touch. <br/></p>
          <p className='mb-4'>I can't wait to hear from you!</p>
          <a href="mailto:prymestudio@gmail.com" className="text-myCyan hover:text-lightCyan">E: Prymestudio@gmail.com</a>
        </div>
        <div></div>
        <div className="mb-6 lg:mb-0">
          <h2 className="lg:text-3xl md:text-1xl sm:text-xl text-xl font-bold mb-8  tracking-tighter leading-tight border-b-2 pb-5 border-cyan-500 inline-block ">Quick Links</h2>
          <ul className='font-inter text-base font-light flex flex-col gap-3'>
            <li><Link to="/about" className="hover:text-cyan-300">About Me</Link></li>
            <li><Link to="/case-study/1" className="hover:text-cyan-300">Holistic Campaign</Link></li>
            <li><Link to="/case-study/2" className="hover:text-cyan-300">Brand Refresh</Link></li>
            <li><Link to="/case-study/3" className="hover:text-cyan-300">Branding</Link></li>
            <li><Link to="/case-study/4" className="hover:text-cyan-300">Type Design</Link></li>
            <li><Link to="/case-study/5" className="hover:text-cyan-300">UX Design</Link></li>
            <li><Link to="/case-study/6" className="hover:text-cyan-300">Motion Design</Link></li>
            {/* Add additional links as needed */}
          </ul>
        </div>
        <div className='pb-10 sm:pb-10 md:pb-10 lg:pb-10'>
          <h2 className="lg:text-3xl md:text-1xl sm:text-xl text-xl font-bold mb-8 tracking-tighter leading-tight border-b-2 pb-5 border-cyan-500 inline-block ">Follow me</h2>
          <ul className='font-inter text-base font-light flex flex-col gap-3'>
            <li><a href="https://linkedin.com/in/jeffery-afiamagbon-161213170" target="_blank" rel="noopener noreferrer" className="hover:text-cyan-300">LinkedIn</a></li>
            <li><a href="https://www.behance.net/jefferyPryme" target="_blank" rel="noopener noreferrer" className="hover:text-cyan-300">Behance</a></li>
            <li><a href="https://dribbble.com/Jefferypryme" target="_blank" rel="noopener noreferrer" className="hover:text-cyan-300">Dribbble</a></li>
            <li><a href="https://www.instagram.com/jeffreypryme/" target="_blank" rel="noopener noreferrer" className="hover:text-cyan-300">Instagram</a></li>
            {/* Add additional social links as needed */}
          </ul>
        </div>
        <div></div>
      </div>
      <div className="border-t border-gray-700 text-center pb-2 sm:pb-2 lg:pb-2 pt-10 md:my-4 lg:my-10 font-inter text-base">
        <p className='mb-4'>Developed in React by <span className=' font-bold text-myCyan'>Jeffrey Pryme</span></p>
        <p>Copyright © {currentYear}</p>
      </div>
    </footer>
  );
};

export default Footer;
