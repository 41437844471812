// ToolIcon.js
import React from 'react';

const ToolIcon = ({ icon: Icon, name }) => {
  return (
    <div className="relative group bg-dark-500 rounded-lg flex items-center justify-center hover:bg-myCyan transition-colors duration-300">
      <Icon className="w-full h-full transition-opacity duration-300 group-hover:opacity-0" />
      <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100">
        <span className="text-black lg:text-4xl md:text-xl sm:text-base text-sm font-bold tracking-tighter leading-tight">{name}</span>
      </div>
    </div>
  );
};

export default ToolIcon;
