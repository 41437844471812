import React from 'react';
import { ReactComponent as ArrowIcon } from './arrows.svg'; // Update with the path to your SVG

const CallToAction = () => {
    return (
      <section className="bg-navy text-white text-center py-12">
        <div className='px-5 sm:px-10 lg:px-24  mt-0 sm:mt-0 md:mt-4 lg:mt-10'>
            <h3 className="lg:text-2xl md:text-xl mb-2 my-16 font-Inter text-lightCyan tracking-wider">NEED MY EXPERTISE?</h3>
            <div className="relative inline-block text-3xl font-bold items-center">
            <span className='lg:text-7xl md:text-6xl sm:text-5xl text-5xl font-bold tracking-tighter leading-tight text-white hover:text-myCyan'>
                <a href="mailto:prymestudio@gmail.com">Let's Work Together</a></span>
            <ArrowIcon className="inline-block ml-6 mb-6 animate-horizontal-bounce" />
            <ArrowIcon className="inline-block ml-0 mb-6 animate-horizontal-bounce" />
            </div>
        </div>
      </section>
    );
  };
  
  export default CallToAction;