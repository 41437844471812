import React from 'react';
import { useParams } from 'react-router-dom';
import caseStudies from './caseStudies'; // Ensure this points to your case studies data

const FilteredCaseStudies = () => {
  let { category } = useParams();
  const filteredCaseStudies = caseStudies.filter(cs => cs.category.toLowerCase().replace(/\s+/g, '-') === category);

  return (
    <div>
      <h2>Case Studies: {category}</h2>
      <ul>
        {filteredCaseStudies.map(cs => (
          <li key={cs.id}>{cs.title}</li> // Display basic info, adjust as needed
        ))}
      </ul>
    </div>
  );
};

export default FilteredCaseStudies;
