import React from 'react';
import Spline from '@splinetool/react-spline';
import ImageCarousel from './components/ImageCarousel';
import ExperienceSection from './components/ExperienceSection';
import CallToAction from './components/CallToAction';
import Footer from './components/Footer';


import { ReactComponent as LinkedInIcon } from './icons/linkedin.svg';
import { ReactComponent as DribbbleIcon } from './icons/dribbble.svg';
import { ReactComponent as BehanceIcon } from './icons/behance.svg';

import { ReactComponent as RedditIcon} from './icons/reddit.svg';
import { ReactComponent as AmazonIcon} from './icons/amazon.svg';
import { ReactComponent as TeslaIcon} from './icons/tesla.svg';
import { ReactComponent as ShopifyIcon} from './icons/shopify.svg';
import { ReactComponent as UberIcon} from './icons/Uber.svg';
import { ReactComponent as LyftIcon} from './icons/lyft.svg';
import { ReactComponent as NetflixIcon} from './icons/netflix.svg';
import { ReactComponent as KingIcon} from './icons/king.svg';


// ... other imports

const socialLinks = [
    { Icon: LinkedInIcon, url: "https://www.linkedin.com/in/yourprofile" },
    { Icon: DribbbleIcon, url: "https://dribbble.com/yourprofile" },
    { Icon: BehanceIcon, url: "https://www.behance.net/yourprofile" },
  ];

const AboutUs = () => {

    const experienceItems = [
        {
            id: 1,
            heading: 'GLOBAL COUNSEL',
            title: 'Brand & Visual Design Editor',
            company: 'Remote - UK',
            period: '2022 - Present',
            description: 'As the Brand and Visual Designer at Global Counsel, I developed and maintained the visual identity and strategy for the brand, ensuring consistency across various mediums. My role involved designing campaign and event branding, marketing materials, emails, digital content, as well as product, UX, and motion design. Collaborating with teams, I aligned these creative efforts with the business objectives, staying abreast of trends to keep the brand relevant and highly regarded.',
            // More details here
        },
        {
            id: 2,
            heading: 'NORTHUMBRIA UNIVERSITY',
            title: 'Lead Digital & Content Designer',
            company: 'Remote - UK',
            period: '2021 - 2022',
            description: "My role encompassed overseeing the design and development of compelling digital content, including websites, social media graphics, and multimedia campaigns, ensuring they align with the university's brand standards. I collaborated closely with various departments to understand their needs and crafted visually appealing and informative designs to support their objectives.",
            // More details here
        },
        {
            id: 3,
            heading: 'TRANSCORP GROUP',
            title: 'Group Brand Designer & Creative Director',
            company: 'Hybrid - Lagos',
            period: '2017 - 2021',
            description: "At Transcorp Group, I was responsible for directing the creative process of various projects, including still and motion graphics, website management, and the development of marketing materials like brochures, email campaigns, and annual reports. Additionally, I led initiatives such as directing storytelling documentaries to push the brand's CSR activities, and the Humans of Transcorp initiative that showcased the company as a great place to work, significantly enhancing brand engagement and storytelling.",
            // More details here
        },
        {
            id: 4,
            heading: 'MARTIN MEDIA',
            title: 'Creative Designer',
            company: 'Remote - USA',
            period: '2016 - 2017',
            description: 'My role as a Creative Designer at the agency involved transforming marketing strategies and objectives into coherent creative strategies, ensuring alignment with the company’s goals and developing execution roadmaps. I managed the creative process from concept to completion, including the pitching and implementation of campaign strategies. Additionally, I branded several businesses, devised brand strategy documents, conducted brand audits, and ensured a compliant digital presence.',
            // More details here
        },
        // ... other experience items
        ];

        const services = [
            "Graphic Design", "Branding", "UX Design", "Motion Graphics",
            "Typography", "Frontend Dev.", "Concept Dev.", "3D Design",
            "Photography", "Videography", "Sound Design", "Trend Analysis",
            "Email Marketing", "Digital Marketing"
          ];

          const icons = [
            { id: 'reddit', Icon: RedditIcon },
            { id: 'amazon', Icon: AmazonIcon },
            { id: 'tesla', Icon: TeslaIcon },
            { id: 'shopify', Icon: ShopifyIcon },
            { id: 'uber', Icon: UberIcon },
            { id: 'lyft', Icon: LyftIcon },
            { id: 'netflix', Icon: NetflixIcon },
            { id: 'king', Icon: KingIcon },
          ];

  return (
    <>
        <section className="relative bg-gradient-to-b from-navy to-black min-h-screen text-white pb-24">  
            {/* Placeholder for your Spline animation */}
                {/* Placeholder for your Spline animation, filling the section */}
            <div className="absolute z-10 w-full h-full">
                <Spline scene="https://prod.spline.design/i3dCHnX0eZAqvDY2/scene.splinecode" />
            </div>

            {/* headers and buttons */}
            <div className="absolute bottom-24 z-30 mx-10 sm:mx-20 lg:mx-80">

                {/* main div */}
                <div className="justify-center"> 
                    <h3 className="lg:text-2xl md:text-xl mb-4 font-Inter text-lightCyan tracking-wider text-center">ME IN A NUTSHELL</h3>
                    <h1 className="lg:text-5xl md:text-3xl sm:text-2xl text-2xl font-bold mb-6 tracking-tighter leading-tight text-center">I am Jeffrey, a multi-disciplinary senior designer specialized in telling brand stories through visuals, building positive brand perception and positioning employers as industry experts</h1>
                    <h2 className="lg:text-2xl md:text-2xl  mb-9 pr-12 tracking-tight font-light text-center">With more than <span className=' font-bold text-myCyan'>10 years</span> of experience in branding, photography, videography, graphic, motion, typography, UX, web, and email design. I possess the skill set needed to serve marketing and media departments efficiently. I collaborate seamlessly with teams, communicate clearly, and deliver captivating presentations that keep stakeholders engaged.  In my free time, I have a passion for photography, enjoy immersing myself in nature, and love traveling with friends.</h2>
                </div>

                {/* Empty div for spacing on large screens */}
                <div className="hidden lg:block"></div>
            </div>


            {/* neon email on the left */}
            <div className="hidden lg:block absolute left-4 bottom-32 transform -rotate-90 transform-origin-bottom-left font-inter font-light text-myCyan">
                <span className=" tracking-ultraTight mr-2">-------------</span>
                <span>prymestudio@gmail.com</span>
            </div>

                {/* social icons */}
            <div className="hidden lg:block absolute right-4 bottom-24 px-12 z-20">
                <div className=' space-y-2'>
                {socialLinks.map(({ Icon, url }, index) => (
                    <a key={index} href={url} target="_blank" rel="noopener noreferrer" className="block text-dark hover:text-myCyan">
                        <Icon className="h-6 w-6" /> {/* Adjust the size as needed */}
                    </a>
                ))}
                </div>
            </div>
        </section>

        {/* carousel */}
        <section className='bg-black'>
            <ImageCarousel />
        </section>

        {/* Experience section */}
        <section className="bg-gradient-to-b from-navy to-black text-white py-1 sm:py-8 md:py-12 lg:py-24 s">
            <div className="px-10 sm:px-20 lg:px-24  mt-1 md:mt-4 lg:mt-10">
                <h3 className="lg:text-2xl md:text-xl mb-4 font-Inter text-lightCyan tracking-wider">WHERE I’VE WORKED</h3>
                <h1 className="lg:text-7xl md:text-6xl sm:text-5xl text-5xl font-bold mb-24  tracking-tighter leading-tight">9 Years of Experience</h1>
            </div>
            <ExperienceSection experienceItems={experienceItems} />
        </section>


        {/* things i can do */}
        <section className="bg-gradient-to-b from-black to-navy py-1 md:py-12 lg:py-24 px-10 sm:px-20 lg:px-24">
            <div className="mt-10 sm:mt-10 md:mt-4 lg:mt-5">
                <h3 className="lg:text-2xl md:text-xl mb-4 font-Inter text-lightCyan tracking-wider">PROFICIENCY</h3>
                <h1 className="lg:text-7xl md:text-6xl sm:text-5xl text-5xl text-white font-bold mb-10 md:mb-16 lg:mb-24 tracking-tighter leading-tight">My Skills</h1>
            </div>
            <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 gap-4 sm:gap-1">
                {services.map((service, index) => (
                    <div key={index} className=" xl:text-2xl lg:text-2xl md:text-2xl sm:text-base text-base text-lightCyan font-bold tracking-tighter leading-tight p-4 text-center border-2 border-navy hover:border-myCyan">
                    {service}
                    </div>
                ))}
            </div>
        </section>


        {/* companies i have worked with */}
        <section className=" bg-gradient-to-b from-navy to-black text-lightCyan  py-1 md:py-12 lg:py-24 px-10 sm:px-20 lg:px-24">
            <div className="mt-10 sm:mt-10 md:mt-4 lg:mt-5">
                <h3 className="lg:text-2xl md:text-xl mb-4 font-Inter text-lightCyan tracking-wider">BRAND PROJECTS I'VE WORKED ON</h3>
                <h1 className="lg:text-7xl md:text-6xl sm:text-5xl text-5xl font-bold mb-10 md:mb-16 lg:mb-24 tracking-tighter leading-tight">Project Owners</h1>
            </div>
            <div className="grid grid-cols-3 sm:grid-cols-3 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 sm:gap-1">
                {icons.map(({ id, Icon }) => (
                    <div key={id} className="flex justify-center items-center border-2 border-navy hover:border-myCyan p-4">
                        <Icon className=" w-full" />
                    </div>
                ))}
            </div>
        </section>

        {/* Call to action */}
        <section>
            <CallToAction />
        </section>


        {/* Footer */}
        <section>
            <Footer />
        </section>                


    </>
        );
};

export default AboutUs;