import React, { useState } from 'react';


const ExperienceSection = ({ experienceItems }) => {

        //code for the experience toggle
        const [activeExperienceId, setActiveExperienceId] = useState(experienceItems[0].id);
    
        const toggleExperience = (id) => {
            setActiveExperienceId(activeExperienceId === id ? null : id);
        };

    return (
        <section className=" text-white">
            <div className="px-10 sm:px-20 lg:px-24  mt-1 md:mt-4 lg:mt-10">                
                {experienceItems.map((item) => (
                <div
                    key={item.id}
                    className={`cursor-pointer p-4 mb-4 ${activeExperienceId === item.id ? 'border-2 px-4 border-myCyan' : 'border-2 px-4 border-navyBlue hover:bg-myCyan hover:text-dark'}`}
                    onClick={() => toggleExperience(item.id)}
                >
                    <div className={`pt-6 ${activeExperienceId === item.id ? 'lg:grid lg:grid-cols-3 md:grid md:grid-cols-3' : 'lg:block md:block '}`}>
                        <div>
                            <h3 className="lg:text-1xl lg:ml-10 md:text-base md:ml-8 ml-4 mb-4 font-Inter text-myCyan tracking-wider">{item.heading}</h3>
                            <h1 className=" lg:text-5xl lg:ml-10 md:text-4xl md:ml-8 ml-4 sm:text-3xl text-3xl font-bold mb-6 tracking-tighter leading-tight">{item.title}</h1>
                        </div>
                        <div className='col-span-2'>
                            {activeExperienceId === item.id && (
                            <div className='lg:grid lg:grid-cols-2 md:grid md:grid-cols-2'>
                                <h3 className="lg:text-1xl md:text-base text-sm ml-4 mb-4 font-Inter text-myCyan tracking-wider">{item.company}</h3>
                                <h3 className="lg:text-1xl md:text-base text-sm ml-4  mb-4 font-Inter text-myCyan tracking-wider lg:text-right md:text-right pr-20 pt">{item.period}</h3>
                            </div> )}
                            {activeExperienceId === item.id && (
                                <p className="lg:text-xl md:text-lg text-sm ml-4 mb-9 pr-12 tracking-tight font-light">{item.description}</p>
                                )}
                        </div>
                    </div>
                </div>
                ))}
            </div>
        </section>
    );
};

export default ExperienceSection;
