import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import caseStudies from './caseStudies'; // Ensure this path correctly points to your case studies data
import CallToAction from './CallToAction';
import Footer from './Footer';

const CaseStudyDetail = () => {
  let { id } = useParams();
  let navigate = useNavigate();

  // Retrieve the current case study based on the ID from the URL
  const caseStudy = caseStudies.find(cs => cs.id.toString() === id);

  // Find the index of the current case study in the array
  const currentCaseStudyIndex = caseStudies.findIndex(cs => cs.id.toString() === id);

  // Function to navigate to the next case study
  const goToNextCaseStudy = () => {
    // Calculate the index of the next case study, wrapping around to the first case study if currently at the last
    const nextCaseStudyIndex = (currentCaseStudyIndex + 1) % caseStudies.length;
    // Retrieve the ID of the next case study
    const nextCaseStudyId = caseStudies[nextCaseStudyIndex].id;
    // Navigate to the next case study
    navigate(`/case-study/${nextCaseStudyId}`);
  };

  // If the case study is not found, render a fallback component or message
  if (!caseStudy) {
    return <div>Case study not found!</div>;
  }

  return (
    <div>
      <div className="page-content">{caseStudy.pageContent}</div>
      {/* Button to navigate to the next case study */}
      <div className=" bg-dark flex flex-col items-center justify-center px-0 sm:px-1 md:px-10 py-10 sm:py-10 md:py-10 lg:py-12 ">
        <button onClick={goToNextCaseStudy} className="border-2 px-4 border-myCyan py-2 text-sm font-inter font-bold text-myCyan sm:px-8 sm:py-4 sm:text-base hover:bg-myCyan hover:text-black">
          Next Case Study
        </button>
      </div>
      {/* call to action */}
      <CallToAction />

      {/* Footer */}
      <Footer />
    </div>

  );
};

export default CaseStudyDetail;
