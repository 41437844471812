import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from './logo.svg'; 
import { ReactComponent as HamburgerIcon } from './hamburger.svg'; 
import { ReactComponent as CloseIcon } from './close.svg'
import myPdf from './JeffreyAP.pdf';

const NavBar = () => {
    const [isScrolled, setIsScrolled] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 20);
        };

        const handleResize = () => {
            if (window.innerWidth > 1024) setIsOpen(false); // Close menu on large screens
        };

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const menuItems = [
        { name: "About", path: "/about", isExternal: false },
        { name: "Case Study", path: "/case-study/3", isExternal: false },
        { name: "My CV", path: myPdf, isExternal: true }, 
        { name: "My LinkedIn", path: "https://linkedin.com/in/jeffery-afiamagbon-161213170", isExternal: true }, 
    ];

    const closeMenu = () => {
        setIsOpen(false);
    };
    
    return (
        <nav
          className={`z-50 fixed top-0 left-0 right-0 py-8 px-12 flex justify-between items-center transition-all duration-300 ${
            isScrolled
              ? 'bg-gradient-to-b from-black via-black/25 to-transparent backdrop-blur'
              : 'bg-transparent'
          }`}
        >
            <Link to="/">
                <Logo />
            </Link>
            <button onClick={() => setIsOpen(!isOpen)} className='relative z-50'>
                {isOpen ? <CloseIcon /> : <HamburgerIcon />}
            </button>
            {isOpen && (
                <div className="fixed top-0 left-0 h-screen w-full z-40 flex flex-col items-center justify-center bg-black overflow-y-auto max-h-screen">
                    {menuItems.map((item, index) => (
                        item.isExternal ? (
                            <a
                              key={index}
                              href={item.path}
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={closeMenu}
                              className="text-3xl md:text-5xl lg:text-6xl font-bold tracking-tighter leading-tight text-white mb-4 animate-slideIn hover:text-myCyan hover:font-light transition duration-300 ease-in-out hover:border-b hover:border-myCyan hover:pb-5 border-b-0"
                            >
                              {item.name}
                            </a>
                        ) : (
                            <Link
                              key={index}
                              to={item.path}
                              onClick={closeMenu}
                              className="text-3xl md:text-5xl lg:text-6xl font-bold tracking-tighter leading-tight text-white mb-4 animate-slideIn hover:text-myCyan hover:font-light transition duration-300 ease-in-out hover:border-b hover:border-myCyan hover:pb-5 border-b-0"
                            >
                              {item.name}
                            </Link>
                        )
                    ))}
                </div>
            )}
        </nav>
    );
};

export default NavBar;
